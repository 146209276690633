import ReceiptIcon from '@mui/icons-material/Receipt';
import DescriptionIcon from '@mui/icons-material/Description';
import PaidIcon from '@mui/icons-material/Paid';

const icons = {
	receipt: ReceiptIcon,
	expense: PaidIcon,
	report: DescriptionIcon,
};

const Reports = {
	type: 'group',
	title: 'รายงาน',
	level: 50,
	children: [
		{
			type: 'collapse',
			title: 'งานขาย',
			icon: icons.receipt,
			url: '/reports/sales',
			children: [
				{
					type: 'item',
					title: 'สรุปยอดขาย',
					icon: icons.report,
					url: '/reports/sales/details',
				},
				{
					type: 'item',
					title: 'ยอดขายแบบสินเชื่อ',
					icon: icons.report,
					url: '/reports/sales/installments',
				},
				{
					type: 'item',
					title: 'รายงานขายสินค้า',
					icon: icons.report,
					url: '/reports/sales/products',
				},
				{
					type: 'item',
					title: 'รายงานลูกค้า',
					icon: icons.report,
					url: '/reports/sales/customers',
				},
				{
					type: 'item',
					title: 'รายงานรับเงิน',
					icon: icons.report,
					url: '/reports/sales/receipts',
				},
				{
					type: 'item',
					title: 'รายงานเช็ครับ',
					icon: icons.report,
					url: '/reports/sales/cheques',
				},
				{
					type: 'item',
					title: 'รายงานเงินโอน',
					icon: icons.report,
					url: '/reports/sales/transfers',
				},
				{
					type: 'item',
					title: 'ส่งไปรษณีย์',
					icon: icons.report,
					url: '/reports/sales/delivery',
				},
				{
					type: 'item',
					title: 'ฝากเงินธนาคาร',
					icon: icons.report,
					url: '/reports/sales/deposits',
				},
				{
					type: 'item',
					title: 'ภาษีขนส่ง',
					icon: icons.report,
					url: '/reports/sales/tax-renewals',
				},
				{
					type: 'item',
					title: 'ประกัน',
					icon: icons.report,
					url: '/reports/sales/insurance',
				},
				{
					type: 'item',
					title: 'ส่งเบี้ย',
					icon: icons.report,
					url: '/reports/sales/premium',
				},
				{
					type: 'item',
					title: 'ลูกหนี้',
					icon: icons.report,
					url: '/reports/sales/receivables',
				},
			],
		},
		{
			type: 'collapse',
			title: 'ค่าใช้จ่าย',
			icon: icons.expense,
			url: '/reports/expenses',
			children: [
				{
					type: 'item',
					title: 'รายการจ่ายเงิน',
					icon: icons.report,
					url: '/reports/expenses/payments',
				},
				{
					type: 'item',
					title: 'สรุปการซื้อสินค้ารายเดือน',
					icon: icons.report,
					url: '/reports/expenses/purchases/summary',
				},
				{
					type: 'item',
					title: 'รายละเอียดการซื้อ',
					icon: icons.report,
					url: '/reports/expenses/purchases/list',
				},
				{
					type: 'item',
					title: 'สต็อกสินค้า',
					icon: icons.report,
					url: '/reports/expenses/inventory',
				},
			],
		},
	],
};

export default Reports;
