import MainLayout from 'layout/MainLayout';

import { getRoutes } from './router';

const routes = [
	{
		path: 'reports',
	},
	{
		path: 'reports/sales',
		children: [
			{
				path: 'reports/sales/details',
			},
			{
				path: 'reports/sales/installments',
			},
			{
				path: 'reports/sales/products',
			},
			{
				path: 'reports/sales/customers',
			},
			{
				path: 'reports/sales/receipts',
			},
			{
				path: 'reports/sales/cheques',
			},
			{
				path: 'reports/sales/transfers',
			},
			{
				path: 'reports/sales/delivery',
			},
			{
				path: 'reports/sales/deposits',
			},
			{
				path: 'reports/sales/tax-renewals',
			},
			{
				path: 'reports/sales/insurance',
			},
			{
				path: 'reports/sales/premium',
			},
			{
				path: 'reports/sales/receivables',
			},
		],
	},
	{
		path: 'reports/expenses',
		children: [
			{
				path: 'reports/expenses/payments',
			},
			{
				path: 'reports/expenses/purchases/summary',
			},
			{
				path: 'reports/expenses/purchases/list',
			},
			{
				path: 'reports/expenses/inventory',
			},
		],
	},
];

const Data = {
	path: '/',
	element: <MainLayout />,
	children: getRoutes(routes, true),
};

export default Data;
